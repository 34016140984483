window.Toast = {
  /**
   * Shows a toast notification
   * @param {Object} options - Toast configuration options
   * @param {string} options.title - Toast title text
   * @param {string} options.message - Toast message text
   * @param {string} options.type - Toast type ('success', 'error', 'warning', 'info')
   * @param {number} options.duration - Duration in ms before toast auto-dismisses (default: 5000)
   */
  show: function({title, message, type = 'success', duration = 5000}) {
    // Define type-specific styles
    const styles = {
      success: {
        header: 'bg-success text-white',
        body: 'text-success',
        icon: 'bi-check-circle-fill'
      },
      error: {
        header: 'bg-danger text-white',
        body: 'text-danger',
        icon: 'bi-x-circle-fill'
      },
      warning: {
        header: 'bg-warning text-dark',
        body: 'text-warning',
        icon: 'bi-exclamation-triangle-fill'
      },
      info: {
        header: 'bg-info text-white',
        body: 'text-info',
        icon: 'bi-info-circle-fill'
      }
    };

    const style = styles[type];

    // Create toast elements
    const alertDiv = document.createElement('div');
    alertDiv.classList.add('toast', 'show');
    alertDiv.innerHTML = `
      <div class="toast-header ${style.header}">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2 opacity-50">
          <use xlink:href="#${style.icon}" />
        </svg>
        <strong class="me-auto text-uppercase font-display">${title}</strong>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body ${style.body}">${message}</div>
    `;

    // Create or get existing toast container
    let containerDiv = document.querySelector('.toast-container');
    if (!containerDiv) {
      containerDiv = document.createElement('div');
      containerDiv.classList.add('toast-container', 'position-fixed', 'top-0', 'end-0', 'p-3');
      document.body.appendChild(containerDiv);
    }

    // Add toast to container
    containerDiv.appendChild(alertDiv);

    // Remove toast after duration
    setTimeout(() => {
      alertDiv.remove();
      // Remove container if empty
      if (!containerDiv.hasChildNodes()) {
        containerDiv.remove();
      }
    }, duration);
  },

  success: function(message, title = 'Success') {
    this.show({
      title,
      message,
      type: 'success'
    });
  },

  error: function(message, title = 'Error') {
    this.show({
      title,
      message,
      type: 'error'
    });
  },

  warning: function(message, title = 'Warning') {
    this.show({
      title,
      message,
      type: 'warning'
    });
  },

  info: function(message, title = 'Info') {
    this.show({
      title,
      message,
      type: 'info'
    });
  }
};
